<template>
  <v-footer ref="footer" :absolute="true">
    <v-container fluid>
      <v-row>
        <v-col class="v-col-12 v-col-sm-3 offset-sm-1 v-col-md-3 offset-md-1 d-flex flex-column justify-space-between">
          <div class="mt-3 mt-sm-0"><v-img :src="dfWhite" width="200" alt="Digital Frontier" /></div>
          <p class="mt-3 mb-3 mb-sm-0 intro">Digital Frontier is a tech-focused media and events company that steps beyond the daily news cycle to produce deeply reported stories on the future of business, finance and culture</p>
          <p class="mt-6 mb-0 rights d-none d-sm-block">&copy; {{ new Date().getFullYear() }} Digital Frontier Media Ltd, A <a href="https://www.blockspace.uk" target="_blank" class="link">Blockspace</a> Company. <br>All rights reserved.</p>
        </v-col>

        <v-col class="v-col-6 v-col-sm-3 offset-sm-1 v-col-md-2 offset-md-1 v-col-lg-2 offset-lg-1 footer-links d-flex flex-column justify-start align-start links">


          <router-link :to="{ name: 'about'}" class="mr-3">About</router-link>
          <router-link :to="{ name: 'contact'}" class="mr-3">Contact</router-link>
          <a href="#" onclick="Optanon.ToggleInfoDisplay()" class="ot-sdk-show-settings">Cookie Settings</a>
          <router-link :to="{ name: 'team'}" class="mr-3">Meet the Team</router-link>
          <router-link :to="{ name: 'press'}" class="mr-3">Press</router-link>
          <router-link :to="{ name: 'content', params: {slug: 'privacy-policy'}}" class="mr-3">Privacy Policy</router-link>
          <router-link :to="{ name: 'subscriptions'}" class="mr-3">Subscriptions</router-link>
          <router-link :to="{ name: 'content', params: {slug: 'terms-of-service'}}" class="mr-3">Terms of Service</router-link>


        </v-col>
        <v-col class="v-col-6 v-col-sm-3 v-col-md-2 v-col-lg-2 footer-links d-flex flex-column justify-space-between justify-md-start align-start links">

          <div class="d-flex flex-column justify-start align-start">

            <router-link :to="{ name: 'future-of', params: {slug: 'business'}}" class="mr-3">Future of Business</router-link>
            <router-link :to="{ name: 'future-of', params: {slug: 'finance'}}" class="mr-3">Future of Finance</router-link>
            <router-link :to="{ name: 'future-of', params: {slug: 'culture'}}" class="mr-3">Future of Culture</router-link>
            <router-link :to="{ name: 'podcast-index'}" class="mr-3">Podcasts</router-link>
            <a href="https://digitalfrontier.newsstand.co.uk" target="_blank">Magazine</a>
<!--            <a href="https://www.convergenceevent.com/" target="_blank">Events</a>-->
          </div>

          <div class="d-none d-sm-block d-md-none mt-3 mt-sm-0">
            <h3 class="mt-0">Contact</h3>
            <p><a href="mailto:info@digitalfrontier.com" class="text-lowercase">info@digitalfrontier.com</a></p>

            <h3 class="mt-4">Careers</h3>
            <p><a href="https://apply.workable.com/digital-frontier" class="link natural">Click here</a> to view our Careers page</p>

            <h3 class="mt-4">Advertise</h3>
            <p><a href="mailto:hayley@digitalfrontier.com" class="link natural">Request a media pack</a></p>
          </div>


          <div class="d-none d-sm-block d-md-none">



            <div class="socials mt-10 mt-sm-0 d-flex flex-row justify-start">
              <a href="https://www.linkedin.com/company/96824907" title="LinkedIn"><v-icon size="30" class="mr-2" icon="fa-brands fa-linkedin"></v-icon></a>
              <a href="https://x.com/DigitalFrontier" title="x (formally Twitter)"><v-icon size="30" class="mr-2" icon="fa-brands fa-square-x-twitter"></v-icon></a>
              <a href="https://www.instagram.com/digifrontier/" title="Instagram"><v-icon size="30" class="mr-2" icon="fa-brands fa-instagram"></v-icon></a>
              <a href="https://www.threads.net/@digifrontier" title="Threads"><v-icon size="30" class="mr-2" icon="fa-brands fa-square-threads"></v-icon></a>
            </div>

            <p class="mt-6 mb-2 mb-sm-0 rights d-block d-sm-none">&copy; {{ new Date().getFullYear() }} Digital Frontier Media Ltd, A <a href="https://www.blockspace.uk" target="_blank" class="link">Blockspace</a> Company. <br>All rights reserved.</p>
          </div>

        </v-col>

        <v-col class="v-col-12 v-col-sm-10 offset-sm-1 v-col-md-2 offset-md-0 footer-links d-block d-sm-none d-md-flex flex-column justify-space-between align-start">
          <div class="mt-3 mb-5 mt-sm-0">
            <h3 class="mt-0">Contact</h3>
            <p><a href="mailto:info@digitalfrontier.com" class="text-lowercase">info@digitalfrontier.com</a></p>

            <h3 class="mt-4">Careers</h3>
            <p><a href="https://apply.workable.com/digital-frontier" class="link natural">Click here</a> to view our Careers page</p>

            <h3 class="mt-4">Advertise</h3>
            <p><a href="mailto:hayley@digitalfrontier.com" class="link natural">Request a media pack</a></p>
          </div>


          <div class="socials mt-10 mt-sm-0 d-flex flex-row justify-start">
              <a href="https://www.linkedin.com/company/96824907" title="LinkedIn"><v-icon size="30" class="mr-2" icon="fa-brands fa-linkedin"></v-icon></a>
              <a href="https://x.com/DigitalFrontier" title="x (formally Twitter)"><v-icon size="30" class="mr-2" icon="fa-brands fa-square-x-twitter"></v-icon></a>
              <a href="https://www.instagram.com/digifrontier/" title="Instagram"><v-icon size="30" class="mr-2" icon="fa-brands fa-instagram"></v-icon></a>
              <a href="https://www.threads.net/@digifrontier" title="Threads"><v-icon size="30" class="mr-2" icon="fa-brands fa-square-threads"></v-icon></a>
          </div>

          <p class="mt-6 mb-2 mb-sm-0 rights d-block d-sm-none">&copy;Digital Frontier Media Ltd, A <a href="https://www.blockspace.uk" target="_blank" class="link">Blockspace</a> Company. <br>All rights reserved.</p>

        </v-col>
      </v-row>

    </v-container>
  </v-footer>
</template>

<script setup>

import router from "@/router";
import { useAccountStore } from '@/stores/account'
const accountStore = useAccountStore()

import dfWhite from '@/assets/df-logo-white.png'

</script>

<style scoped lang="scss">
footer {
  padding-top: 20px;
  padding-bottom: 20px;
  @media only screen and (min-width: 600px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  background-color: rgb(var(--v-theme-surface));
  color: white;
}

.footer-links a {
  padding: 3px 0;
  font-family: "GroteskLight", sans-serif !important;
  text-decoration: none;
  text-transform: uppercase;
}

footer a:hover {
  text-decoration: underline;
  color: white;
}
footer a, footer a:active, footer a:visited {
  color: white;
  text-decoration: none;
}
footer .link {
  text-decoration: underline;
}
footer h2 {
  font-family: "GroteskLight", sans-serif;
  color: rgb(var(--v-theme-primary));
  font-weight: normal;
  font-size: 5em;
  line-height: 0.9em;
  margin-top: 4px;
  text-transform: capitalize;
}
@media only screen and (min-width: 1280px) {
  footer h2 {
    font-size: 7em;
  }
}
footer h3 {
  font-family: "GroteskBold", sans-serif;
  color: rgb(var(--v-theme-primary));
  font-weight: bold;
  font-size: 1.25rem;
}
footer p {
  font-size: 0.875rem;
}

footer .links a {
  font-size: 0.875rem;
  text-transform: none;
}

footer {
  p.intro {
    //font-family: "GroteskScreen", sans-serif !important;
    line-height: 1.5em;
  }
  p.rights {
    font-family: "GroteskLight", sans-serif !important;
    font-size: 0.75rem;
  }
}

.natural {
  text-transform: none !important;
}

</style>
