<template>
<!--  <v-container :fluid="true">-->

  <div :class="'article-page vertical-' + blok.vertical">
    <div v-editable="blok">

      <component is="header" :blok="blok.header" :name="name" :reporters="blok.reporters" :dark="blok.dark" :first_published_at="first_published_at" :vertical="blok.vertical" :tags="tags" :opEd="blok.op_ed"></component>

    </div>

<!--    <v-container fluid :class="background">-->
<!--      <v-container>-->
<!--        <v-row>-->
<!--          <v-col class="v-col-6 offset-3">-->
<!--            <p class="font-lora">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>-->
<!--          </v-col>-->
<!--          <v-col class="v-col-3">-->
<!--            <p class="quote">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>-->
<!--          </v-col>-->
<!--        </v-row>-->
<!--      </v-container>-->



    <div v-if="blok.content" v-editable="blok" :class="!isAllowed ? 'content-container fade-out' : 'content-container'">

      <div v-if="blok.intro_text" v-editable="blok">

        <component is="intro_text" :blok="blok.intro_text"></component>

      </div>

      <div class="content-inner">
        <StoryblokComponent
            v-for="(inblok, index) in blok.content"
            :blok="inblok"
            :index="index"
            :opEd="blok.op_ed"
            :key="inblok._uid"
            :person="blok.reporters.length > 0 ? blok.reporters[0] : null"
        />
      </div>


      <v-container v-if="tags.length > 0" fluid>
        <v-row>
          <v-col class="v-col-12 offset-0 v-col-sm-10 offset-sm-1 v-col-md-8 offset-md-2 v-col-lg-6 offset-lg-3">
            <v-btn v-for="tag in tags" variant="flat" density="compact" color="tag" class="tag text-uppercase mr-3" @click="search(tag)">{{ tag }}</v-btn>
          </v-col>
        </v-row>
      </v-container>

      <v-container fluid>
        <v-row>
          <v-col class="v-col-12 offset-0 v-col-sm-10 offset-sm-1 v-col-md-8 offset-md-2 v-col-lg-6 offset-lg-3 mt-0 pb-0">
            <div class="text-center">

              <a href="https://digitalfrontier.newsstand.co.uk" target="_blank">
                <v-img class="d-none d-sm-block mb-10 mt-4" width="100%" :src="magazineBanner" />
                <v-img class="d-block d-sm-none mb-4" width="100%" :src="magazineMpu" />
              </a>

            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

  </div>
</template>

<script setup>
import router from "@/router";

const props = defineProps({ blok: Object, first_published_at: String, showPaywall: Boolean, tags: Array, name: String, isAllowed: Boolean })
console.log(props.blok)
import { computed } from 'vue'

import magazineBanner from '@/assets/magazine-banner.jpg'
import magazineMpu from '@/assets/magazine-mpu.jpg'
const mode = import.meta.env.MODE;

const search = (tag) => {
  router.push({name: 'search-tag', params: {tag: tag}})
}

const adsenseClientId = () => {
  return import.meta.env.VITE_ADSENSE_CLIENT_ID
}

const adsenseAdSlotId = () => {
  return import.meta.env.VITE_ADSENSE_AD_SLOT_ID
}

const background = computed(() => {
  if (props.blok.dark) {
    return 'background-dark'
  }

  return 'background-light'
})

const shouldShowTeaser = computed(() => {
  if (document.referrer === 'https://app.storyblok.com/') {
    return false
  }
  if (props.showPaywall) {
    return true
  }
  return false
})

</script>

<style lang="scss">

.vertical-business .quote-marks {
  color: rgb(var(--v-theme-business));
}

.vertical-finance .quote-marks {
  color: rgb(var(--v-theme-finance));
}

.vertical-culture .quote-marks {
  color: rgb(var(--v-theme-culture));
}

.article-page {
  .fade-out {
    .v-container {
      position: relative;
    }

    margin-bottom: 120px;
    @media only screen and (min-width: 600px) {
      margin-bottom: 40px;
    }
  }

  .fade-out .v-container:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(rgba(255, 255, 255, 0) 50%, #F1ECE5);
  }

  .full-width-quote {
    background-color: #333;
    color: rgb(var(--v-theme-background));
  }

  .full-width-quote .quote-text {
    font-size: 3em;
    padding-top: 60px;
    line-height: 1.2em;
    font-family: "GroteskRegular", serif !important;
    font-weight: normal;
  }

  .quote-marks {
    font-size: 14em;
    line-height: 1.3em;
    font-family: "GroteskScreen", serif !important;
  }

  .quote-end {
    rotate: 180deg;
    margin-bottom: -20px;
  }

  .quote-credit {
    font-family: "GroteskMonoRegular", sans-serif !important;
    text-transform: uppercase;
    font-size: 1em;
    padding-bottom: 44px;
  }

  .background-light .full-width-quote {
    background-color: #333;
    color: #fff;
  }

  .highlight {
    color: v-bind('highlightColour')
  }

  .content-inner .v-container:first-of-type p:not(.biog):first-child::first-letter {
    padding: 0 .3rem 0 0;
    margin: 0.1em .3rem 0 0;
    @media only screen and (min-width: 600px) {
      font-size: 7rem;
      margin: 0.1em .7rem 0 0;
    }
    font-size: 4rem;
    float: left;
    line-height: 0.7em;
    font-family: "MagrebBold", serif !important;
    font-weight: bold;
  }

  h2, h3 {
    font-family: "GroteskLight", serif !important;
    font-weight: normal;
    margin-bottom: 0.3em;
  }

  //.copyright {
  //  font-family: "GroteskMonoRegular", sans-serif !important;
  //  text-transform: uppercase;
  //  font-size: 0.9375em;
  //}

  blockquote {
    border-left: solid 3px lightgrey;
    padding-left: 1em;
    font-style: italic
  }

  .content-container {
    margin-top: 58px;
  }

  @media only screen and (max-width: 599px) {
    .content-container {
      margin-top: 27px;
    }
    .body-text {
      font-size: 1.2em;
      margin-bottom: 0;
    }
    h2 {
      font-size: 1.2em;
    }
    h3 {
      font-size: 1.2em;
    }
    .full-width-quote .quote-text {
      font-size: 1.2em;
      padding-top: 20px;
      line-height: 1.2em;
    }
    .quote-marks {
      font-size: 3.5em;
      line-height: 1.4em;
    }
    .quote-credit {
      font-size: 0.8em;
      padding-bottom: 10px;
    }
    .copyright {
      font-size: 0.8em;
    }
  }

  @media only screen and (min-width: 600px) {
    .body-text {
      font-size: 1.4em;
    }
    h2 {
      font-size: 1.8em;
    }
    h3 {
      font-size: 1.2em;
    }
    .full-width-quote .quote-text {
      font-size: 2em;
      padding-top: 30px;
      line-height: 1.2em;
    }
    .quote-marks {
      font-size: 8em;
      line-height: 1.2em;
    }
    .quote-credit {
      font-size: 1em;
      padding-bottom: 20px;
    }
  }

  @media only screen and (min-width: 960px) {
    .body-text {
      font-size: 1.4em;
    }
  }
}
</style>
