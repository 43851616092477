<template>
  <v-container :fluid="true" :class="'vertical-' + vertical">
    <component v-if="blok[0].full_width" is="lead-photo-full" :blok="blok[0]" :name="name" :reporters="reporters" :first_published_at="first_published_at" :vertical="vertical" :tags="tags" :opEd="opEd"></component>
    <component v-else is="lead-photo" :blok="blok[0]" :name="name" :reporters="reporters" :first_published_at="first_published_at" :vertical="vertical" :tags="tags" :opEd="opEd"></component>
  </v-container>
</template>

<script setup>
const props = defineProps({ blok: Object, reporters: Object, dark: Boolean, first_published_at: String, vertical: String, tags: Array, name: String, opEd: Boolean })
console.log(props)
import { computed } from 'vue'

const background = computed(() => {
  if (props.dark) {
    return 'background-dark'
  }

  return 'background-light'
})

</script>

<style scoped>
.v-container--fluid {
  padding: 0 !important;
}
.background-dark {
  background-color: #222222;
  color: white;
}
.background-light {
  background-color: white;
  color: black;
}
</style>
