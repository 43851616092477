<template>
  <div v-editable="blok">
    <v-row>
      <v-col class="v-col-12 v-col-md-10 offset-md-1">
        <v-row class="story-list">
          <v-col v-if="blok" class="v-col-12 v-col-sm-6 v-col-lg-4 mb-6" v-for="story in blok" :key="story._uid">

              <v-card
                  elevation="0"
                  color="transparent"
              >
                <router-link v-if="story.full_slug.startsWith('podcasts/')" :to="{ name: 'podcast', params: {category: getCategory(story), slug: story.slug}}">
                  <v-img
                      v-if="story.content.listing_image && story.content.listing_image.filename"
                      :src="story.content.listing_image.filename + '/m/700x394/filters:focal('+ story.content.listing_image.focus +')'"
                      class="align-end"
                      aspect-ratio="16/9"
                      lazy-src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                      contain
                      :alt="story.content.listing_image.alt"
                  >
                  </v-img>
                  <h3 class="pr-8">{{ story.name }}</h3>
                </router-link>
                <router-link v-else-if="story.full_slug.startsWith('articles/')" :to="{ name: 'article', params: {slug: story.slug}}">
                  <v-img
                      v-if="story.content.header && story.content.header[0].listing_image && story.content.header[0].listing_image.filename"
                      :src="story.content.header[0].listing_image.filename + '/m/700x394/filters:focal('+ story.content.header[0].listing_image.focus +')'"
                      lazy-src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                      class="align-end"
                      aspect-ratio="16/9"
                      contain
                      :alt="story.content.header[0].listing_image.alt"
                  >
                  </v-img>
                  <v-img
                      v-else-if="story.content.header && story.content.header[0].background_image"
                      :src="story.content.header[0].background_image.filename + '/m/700x394/filters:focal('+ story.content.header[0].background_image.focus +')'"
                      lazy-src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                      class="align-end"
                      aspect-ratio="16/9"
                      contain
                      :alt="story.content.header[0].background_image.alt"
                  >
                  </v-img>
                  <h3 class="pr-8">{{ story.name }}</h3>
                </router-link>
                <v-card-text class="pa-0 pr-8 mr-0 reporter">
                  <authors :reporters="story.content.reporters" />
                  <v-btn v-if="story.content.vertical && router.currentRoute.value.name !== 'future-of'" variant="flat" density="compact" color="tag" class="tag text-uppercase">FUTURE OF {{ story.content.vertical }}</v-btn>
                  <p class="standfirst" v-if="story.content.header">{{story.content.header[0].standfirst}}</p>
                  <p class="standfirst" v-if="story.content.intro">{{story.content.intro}}</p>
                </v-card-text>

              </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

  </div>
</template>

<script setup>
const props = defineProps({ blok: Object })

import Authors from "@/components/Authors.vue";

import router from "@/router";

const getCategory = (story) => {
  if (story.full_slug) {
    let path = story.full_slug.split("/")
    let category = path[1].split('-')
    return category[1]
  }
}

</script>

<style scoped>
.story-list {
  margin-top: 48px;
  margin-bottom: 48px;
}
.story-list h3 {
  font-family: "GroteskRegular", serif !important;
  font-weight: normal;
  font-size: 1.75em;
  margin-top: 14px;
  margin-bottom: 12px;
}
.story-list .standfirst {
  font-family: "MagrebRegular", serif !important;
  font-size: 1.177em;
  margin-top: 12px;
}

.story-list .reporter {
  font-family: "GroteskMonoRegular", serif !important;
  margin-right: 10px;
}

@media only screen and (max-width: 599px) {
  .story-list {
    margin-top: 12px
  }
}

@media only screen and (min-width: 960px) {
  .story-list .v-col {
    padding-left: 24px;
    padding-right: 24px;
  }
  .story-list {
    margin-left: -24px;
    margin-right: -24px;
  }
}

.v-card {
  border-radius: 0;
}

</style>
