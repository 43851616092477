import { createRouter, createWebHistory } from 'vue-router'
import {useAccountStore} from "@/stores/account";
import {useIndexStore} from "@/stores";
import {accountService} from "@/services"
import {useHead} from "@unhead/vue";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('../views/HomeView.vue'),
      alias: ['/home']
    },
    {
      path: '/search',
      name: 'search',
      component: () => import('../views/SearchView.vue')
    },
    {
      path: '/search/:tag',
      name: 'search-tag',
      component: () => import('../views/SearchView.vue'),
      props: true
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('../views/RegisterView.vue')
    },
    {
      path: '/payment',
      name: 'payment',
      component: () => import('../views/PaymentView.vue')
    },
    {
      path: '/forgotten-password',
      name: 'forgotten-password',
      component: () => import('../views/ForgottenPasswordView.vue')
    },
    {
      path: '/reset-password/:token',
      name: 'reset-password',
      component: () => import('../views/ResetPasswordView.vue'),
      props: true
    },
    {
      path: '/signin',
      name: 'login',
      component: () => import('../views/LoginView.vue')
    },
    {
      path: '/logout',
      name: 'logout',
      beforeEnter: () => {
        let accountStore = useAccountStore()

        if(accountStore.authenticated) {

          accountService.logout().then(() => {
            router.push('/').catch(() => {})
          })

        } else {
          router.push('/').catch(() => {})
        }
      }
    },
    {
      path: '/subscribe',
      name: 'subscriptions',
      component: () => import('../views/PlansView.vue'),
      beforeEnter: () => {

        let accountStore = useAccountStore()

        if(accountStore.authenticated && accountStore.user.subscribed) {

          router.push({name: 'account'}).catch(() => {})

        }
      }
    },
    {
      path: '/welcome',
      name: 'welcome',
      component: () => import('../views/WelcomeView.vue')
    },
    {
      path: '/articles/:slug',
      name: 'article',
      component: () => import('../views/ArticleView.vue'),
      props: true,
      beforeEnter: () => {
        let accountStore = useAccountStore()

        if(accountStore.authenticated && !accountStore.user.email_verified_at) {

          router.push({name: 'verify'}).catch(() => {})

        }
      }
    },
    {
      path: '/author/:slug',
      name: 'person',
      component: () => import('../views/PersonView.vue'),
      props: true
    },
    {
      path: '/future-of/:slug',
      name: 'future-of',
      component: () => import('../views/FutureOfView.vue'),
      props: true
    },
    {
      path: '/:slug',
      name: 'content',
      alias: ['/content/:slug'],
      component: () => import('../views/ContentView.vue'),
      props: true
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('../views/AboutView.vue')
    },
    {
      path: '/account',
      name: 'account',
      component: () => import('../views/AccountView.vue')
    },
    {
      path: '/press',
      name: 'press',
      component: () => import('../views/PressView.vue')
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('../views/ContactView.vue')
    },
    {
      path: '/meet-the-team',
      name: 'team',
      component: () => import('../views/TeamView.vue'),
      beforeEnter: () => {
        if (typeof pSUPERFLY !== 'undefined') {
          pSUPERFLY.virtualPage({
            sections: "Meet the team",
            authors: "",
            path: "/meet-the-team",
            title: "Digital Frontier | Meet the team"
          });
        }
      }
    },
    {
      path: '/podcasts',
      name: 'podcast-index',
      component: () => import('../views/PodcastIndexView.vue')
    },
    {
      path: '/podcasts/the-:category',
      name: 'podcast-category',
      component: () => import('../views/PodcastCategoryView.vue'),
      props: true
    },
    {
      path: '/podcasts/the-:category/:slug',
      name: 'podcast',
      component: () => import('../views/PodcastView.vue'),
      props: true
    },
    {
      path: '/verify',
      name: 'verify',
      component: () => import('../views/VerifyView.vue')
    },
    {
      path: '/verified',
      name: 'verified',
      component: () => import('../views/VerifiedView.vue')
    },
    {
      path: '/free-magazine',
      name: 'free-magazine',
      component: () => import('../views/FreeMagazineView.vue')
    },
    {
      path: '/404',
      name: '404',
      component: () => import('../views/404View.vue')
    },
    { path: '/:pathMatch(.*)*', redirect: '/404' }
  ]
})

router.beforeEach((to, from) => {

  useHead({
    link: {
      href: 'https://digitalfrontier.com' + to.fullPath,
      rel: 'canonical'
    }
  })

  let indexStore = useIndexStore()

  if (from.name) {
    indexStore.newPage()
  }
})

export default router
