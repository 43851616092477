<template>
  <div v-if="blok" class="d-block d-sm-none">
    <router-link :to="{ name: 'article', params: {slug: blok.slug}}" class="no-underline">
      <v-img
          v-if="leadStoryImageMobile && leadStoryImageMobile.filename"
          aspect-ratio="4/6"
          lazy-src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
          :src="leadStoryImageMobile.filename + '/m/600x900/filters:focal('+ leadStoryImageMobile.focus +'):brightness(' + leadStoryBackgroundBrightnessMobile.value + '):blur(' + leadStoryBackgroundBlurMobile.value + ')'"
          :alt="leadStoryImageMobile.alt"
      >
        <lead-story-headline-mobile :blok="blok" :headlineTopMobile="headlineTopMobile" :headlineColorMobile="headlineColorMobile" :hideLogoMobile="hideLogoMobile" />
      </v-img>
      <v-img
          v-else-if="blok.content.header[0].background_image_mobile && blok.content.header[0].background_image_mobile.filename"
          aspect-ratio="4/6"
          lazy-src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
          :src="blok.content.header[0].background_image_mobile.filename + '/m/600x900/filters:focal('+ leadStoryImage.focus +'):brightness(' + leadStoryBackgroundBrightness.value + '):blur(' + leadStoryBackgroundBlur.value + ')'"
          :alt="blok.content.header[0].background_image_mobile.alt"
      >
        <lead-story-headline-mobile :blok="blok" :headlineTopMobile="headlineTopMobile" :headlineColorMobile="headlineColorMobile" :hideLogoMobile="hideLogoMobile" />
      </v-img>
      <v-img
          v-else-if="blok"
          aspect-ratio="4/6"
          lazy-src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
          :src="blok.content.header[0].background_image.filename + '/m/600x900/filters:focal('+ blok.content.header[0].background_image.focus +'):brightness(' + blok.content.header[0].background_brightness.value + '):blur(' + blok.content.header[0].background_blur.value + ')'"
          :alt="blok.content.header[0].background_image.alt"
      >
        <lead-story-headline-mobile :blok="blok" :headlineTopMobile="headlineTopMobile" :headlineColorMobile="headlineColorMobile" :hideLogoMobile="hideLogoMobile" />
      </v-img>
    </router-link>
  </div>
  <div v-if="blok" class="d-none d-sm-block">
    <router-link :to="{ name: 'article', params: {slug: blok.slug}}" class="no-underline">
      <v-img
        v-if="blok"
        aspect-ratio="16/9"
        :srcset="leadStoryImage.filename + '/m/1280x720/filters:focal('+ leadStoryImage.focus +'):brightness(' + leadStoryBackgroundBrightness.value + '):blur(' + leadStoryBackgroundBlur.value + ') 1280w,' + leadStoryImage.filename + '/m/1920x1080/filters:focal('+ leadStoryImage.focus +'):brightness(' + leadStoryBackgroundBrightness.value + '):blur(' + leadStoryBackgroundBlur.value + ') 1920w,' + leadStoryImage.filename + '/m/2560x1440/filters:focal('+ leadStoryImage.focus +'):brightness(' + leadStoryBackgroundBrightness.value + '):blur(' + leadStoryBackgroundBlur.value + ') 2560w'"
        sizes="(min-width: 960px) 1280px, (min-width: 1280px) 1920px, 2560px"
        :src="leadStoryImage.filename + '/m/960x540/filters:focal('+ leadStoryImage.focus +'):brightness(' + leadStoryBackgroundBrightness.value + '):blur(' + leadStoryBackgroundBlur.value + ')'"
        lazy-src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
        :alt="leadStoryImage.alt"
      >
        <div class="home-lead-story d-flex flex-column justify-space-around">
          <div>
            <v-row>
              <v-col v-if="leadStoryHeadlinePosition === 'left'" class="v-col-4" :style="'color:' + headlineColor">
                <div class="d-flex flex-column justify-space-start" style="margin-left: 64px">
                  <h1 :class="'font-' + blok.content.header[0].headline_font_face + (leadStoryHeadlineAlignment === 'left' ? ' text-left' : ' text-right')">{{ blok.name }}</h1>
                  <div :class="'view-more' + (leadStoryHeadlineAlignment === 'right' ? ' text-right' : '')">Read more</div>
                </div>
              </v-col>
              <v-col v-else-if="leadStoryHeadlinePosition === 'center'" class="v-col-6 offset-3" :style="'color:' + headlineColor">
                <div class="d-flex flex-column justify-space-start align-center">
                  <h1 :class="'text-center font-' + blok.content.header[0].headline_font_face">{{ blok.name }}</h1>
                  <div class="view-more text-center">Read more</div>
                </div>
              </v-col>
              <v-col v-else class="v-col-4 offset-8" :style="'color:' + headlineColor">
                <div class="d-flex flex-column justify-space-start" :style="'margin-right: 64px; ' +  (leadStoryHeadlineMarginTop > 0 ? 'margin-top: ' + leadStoryHeadlineMarginTop + 'px; margin-bottom: -' + leadStoryHeadlineMarginTop + 'px' : '')">
                  <h1 :class="'font-' + blok.content.header[0].headline_font_face + (leadStoryHeadlineAlignment === 'left' ? ' text-left' : ' text-right')">{{ blok.name }}</h1>
                  <div :class="'view-more' + (leadStoryHeadlineAlignment === 'right' ? ' text-right' : '')">Read more</div>
                </div>
              </v-col>
            </v-row>
          </div>
          <div class="d-flex justify-end">
            <v-row>
              <v-col class="v-col-12">
                <v-img v-if="!hideLogo" :src="logo" :eager="true" lazy-src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=" alt="Digital Frontier" max-width="100%" class="ml-16 mr-16 df-large-logo"></v-img>
                <v-img v-else :src="alpha" :eager="true" max-width="100%" class="ml-16 mr-16 df-large-logo"></v-img>

              </v-col>
            </v-row>
          </div>
        </div>

      </v-img>
    </router-link>
  </div>

<!--<div class="mt-16">-->
<!--  <v-container fluid>-->
<!--    <v-row>-->
<!--      <v-col class="v-col-8">-->
<!--        <v-img aspect-ratio="1/1" :src="blok.content.header[0].background_image.filename + '/m/500x500/filters:focal('+ blok.content.header[0].background_image.focus +')'">-->

<!--        </v-img>-->
<!--      </v-col>-->
<!--      <v-col class="v-col-4 d-flex flex-column justify-space-between">-->

<!--          <div>-->
<!--            <v-img aspect-ratio="16/9" :src="blok.content.header[0].background_image.filename + '/m/600x300/filters:focal('+ blok.content.header[0].background_image.focus +')'">-->

<!--            </v-img>-->
<!--          </div>-->
<!--          <div>-->
<!--            <v-img aspect-ratio="1/1" :src="blok.content.header[0].background_image.filename + '/m/500x500/filters:focal('+ blok.content.header[0].background_image.focus +')'">-->

<!--            </v-img>-->
<!--          </div>-->

<!--      </v-col>-->
<!--    </v-row>-->
<!--  </v-container>-->



<!--</div>-->
</template>

<script setup>
import {computed} from "vue";

let props = defineProps({ blok: Object, leadStoryImage: Object, leadStoryImageMobile: Object, leadStoryHeadlineColour: Object, leadStoryHeadlinePosition: String, leadStoryHeadlineAlignment: String, leadStoryBackgroundBrightness: Object, leadStoryBackgroundBlur: Object, leadStoryHeadlineColourMobile: Object, leadStoryBackgroundBrightnessMobile: Object, leadStoryBackgroundBlurMobile: Object, headlineTopMobile: Boolean, hideLogo: Boolean, hideLogoMobile: Boolean, leadStoryHeadlineMarginTop: Number })

import logo from '@/assets/df-logo-DFFF4F.png'
import alpha from '@/assets/alpha.png'
import LeadStoryHeadlineMobile from "@/components/LeadStoryHeadlineMobile.vue";

const leadStoryImage = computed(() => {
  let image = props.blok.content.header[0].background_image

  if (props.leadStoryImage.filename) {
    image = props.leadStoryImage
  }
  return image
})

const headlineColor = computed(() => {
  if (props.leadStoryHeadlineColour) {
    return props.leadStoryHeadlineColour.color
  }
  return props.blok.content.header[0].headline_colour.color
})

const headlineColorMobile = computed(() => {
  if (props.leadStoryHeadlineColourMobile) {
    return props.leadStoryHeadlineColourMobile.color
  }
  return props.blok.content.header[0].headline_colour.color
})

</script>

<style scoped lang="scss">
a:hover {
  h1 {
    text-decoration: none;
  }
  .view-more {
    text-decoration: underline;
  }
}
</style>

<style lang="scss">
.home-lead-story {
  height: 100%;

  .gradient {
    background-image: linear-gradient(to top, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0) 100%);
  }
  .gradient-top {
    background-image: linear-gradient(to bottom, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0) 100%);
  }
  .gradient-bottom {
    background-image: linear-gradient(to top, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0) 100%);
  }
  h1, h2.mobile-h1 {
    font-family: "GroteskRegular", sans-serif !important;
  }
  .view-more {
    font-family: "GroteskScreen", sans-serif !important;
  }


  @media only screen and (max-width: 599px) {
    h1, h2.mobile-h1 {
      font-size: 1.6em;
      line-height: 1.1em;
    }
  }

  @media only screen and (min-width: 600px) {
    h1, h2.mobile-h1 {
      font-size: 1.6em;
      line-height: 1.1em;
    }
  }

  @media only screen and (min-width: 960px) {
    h1, h2.mobile-h1 {
      font-size: 2em;
    }
  }

  @media only screen and (min-width: 1280px) {
    h1, h2.mobile-h1 {
      font-size: 3em;
    }
  }
}


</style>
